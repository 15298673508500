<template>
  <div class="columns" ref="top" id="top">
    <div class="column w-full lg:w-3/6">
      <div class="columns">
        <div class="column w-full">
          <info v-model="link" v-if="link.id" :active-same-domains="same_domain_active_links" @contact-form-updated="setEmailFormContactUrl" @on-update="refreshEmailPreview" />
        </div>
        <div class="column w-full">
          <content-item v-model="link" v-if="link.id"/>
        </div>
        <div class="column w-full" v-if="showPaymentsComponent && false">
          <payment-note v-model="link" v-if="link.id" @update="update" :collapsed="paymentComponentCollapsed" @on-update="refreshEmailPreview" />
        </div>
        <div class="column w-full" v-if="showPaymentsComponent || true">
          <payments v-model="link" v-if="link.id" @update="update" :collapsed="paymentComponentCollapsed" @on-update="refreshEmailPreview" />
        </div>
        <div class="column w-full">
          <contacts :id="link.id" ref="contactWidget"
                    include-links :exclude-domain="link.domain" v-if="link.id" :contacts.sync="email_contacts"
                    :collapsed="contactsCollapsed" @loaded="contactsLoaded" @on-update="refreshEmailPreview" />
        </div>
        <div class="column w-full">
          <email-list
            :id="link.id" ref="emailList" v-if="link.id" :contacts="email_contacts" @send-email="setBlankNewEmail"
            :show-send-email-button="false" @reply-to="setReplyEmail" :collapsed="emailListCollapsed" :owner-id="link.owner_id || link.created_by_id"
            @default-to-and-from="setDefaultToAndFrom" @block-email="blockEmail" />
        </div>
        <div class="column w-full" v-if="link.id && show_email_form" ref="emailFormHolder">
          <email-form v-model="link" :value="link" v-if="link.id" :contacts="email_contacts" ref="emailForm" :last-email="lastReceivedEmail"
                      @success="refreshEmailList" :next-email-link="nextEmailLink" :default-to="defaultEmailTo" :default-from="defaultEmailFrom" />
        </div>
      </div>
    </div>
    <div class="column w-full lg:w-3/6">
      <div class="columns">
        <div class="column w-full xxl:w-2/3">
          <scores v-model="link" v-if="link.id" />
          <div class="w-full" v-if="showLinkCheckWidget">
            <check-link v-model="link" v-if="link.id" :collapsed="linkCheckWidgetCollapsed" />
          </div>
          <div class="w-full">

            <relationship

              :id="link.id" v-if="link.id" v-model="link" :collapsed="relationshipWidthCollapsed"  @same-domains="(v) => this.same_domain_active_links = v" />
          </div>
          <div class="w-full">
            <heat-rating v-model="link" v-if="link.id" />
          </div>
        </div>
        <div class="column w-full xxl:w-1/3">
          <notes-widget :resource-id="link.id" v-if="link.id" :default-assignee-id="link.owner_id" resource-type="SiteLink" />
        </div>
      </div>
    </div>
  </div>
</template>

<script>
  import EmailList from "@/components/views/links/EmailList";
  import Contacts from "@/components/views/links/Contacts";
  import EmailForm from "@/components/views/links/EmailForm";
  import Info from "@/components/views/links/Info";
  import NotesWidget from "@/components/notes/index";
  import CheckLink from "@/components/views/links/CheckLink";
  import Scores from '@/components/views/links/Scores';
  import Payments from "@/components/views/links/Payments";
  import ContentItem from "@/components/views/links/ContentItem";
  import Relationship from "@/components/views/links/Relationship";
  import HeatRating from "@/components/views/links/HeatRating";
  import PaymentNote from "@/components/views/links/PaymentNote";

  export default {
     name: 'Lead',
    components: {
      PaymentNote,
      HeatRating,
      Relationship,
      ContentItem, CheckLink, Payments, NotesWidget, Info, EmailForm, EmailList, Contacts, Scores},
    props: {
      value: {
        type: Object,
        default() {
          return {}
        }
      },
      nextEmailLink: {
        type: Object,
        default: null
      }
    },
    watch: {
      value(val) {
        this.link = val;
      }
    },
    data() {
      return {
        show_email_form: true,
        link: this.value,
        email_templates: [],
        email_contacts: [],
        defaultEmailTo: null,
        defaultEmailFrom: null,
        lastReceivedEmail: null,
        same_domain_active_links: [],
      }
    },
    computed: {
      showPaymentsComponent() {
        // if(!this.$store.getters['auth/hasPermission']('payment.read')) {
        //   return false
        // }
        return [
          'active', 'active_renewal', 'awaiting_client_approval', 'awaiting_content', 'awaiting_fulfillment',
            'awaiting_payment', 'awaiting_placement', 'awaiting_renewal', 'client_approved', 'client_declined',
            'content_available', 'correction', 'correction_waiting_for_reply', 'dropped', 'future', 'negotiation',
            'negotiation_reminder_sent', 'negotiation_waiting_for_reply', 'on_hold', 'refund', 'refunded', 'rejected',
            'zombie', 'renewal_negotiation', 'renewal_negotiation_waiting_for_reply', 'waiting_for_refund',
            'waiting_for_tier_1_approval', 'waiting_for_tier_3_approval'
        ].includes(this.value.status) && !['free', 'guest_post_free', 'free_resource_link'].includes(this.value.link_type)
      },
      paymentComponentCollapsed() {
        return [
          'awaiting_content', 'awaiting_fulfillment', 'awaiting_placement', 'client_approved', 'client_declined',
          'content_available', 'correction', 'correction_waiting_for_reply', 'dropped', 'future', 'negotiation',
          'negotiation_reminder_sent', 'negotiation_waiting_for_reply', 'on_hold', 'rejected',
            'zombie'

        ].includes(this.value.status)
      },
      contactsCollapsed() {
        return !['awaiting_contact_info'].includes(this.value.status)
      },
      emailListCollapsed() {
        return ![
          '2nd_initial_sent', '3nd_initial_sent', '4th_initial_sent', 'awaiting_contact_info', 'awaiting_content', 'awaiting_fulfillment',
          'awaiting_placement', 'client_approved', 'client_declined', 'contact_info_retrieved', 'content_available',
          'correction', 'correction_waiting_for_reply', 'future', 'negotiation', 'negotiation_reminder_sent',
          'negotiation_waiting_for_reply', 'new', 'waiting_for_initial_reply'
        ].includes(this.value.status)
      },
      showLinkCheckWidget() {
        return [
          'active', 'active_renewal', 'awaiting_fulfillment', 'awaiting_payment', 'awaiting_placement', 'awaiting_renewal',
          'correction', 'correction_waiting_for_reply', 'existing', 'refund', 'refunded', 'rejected', 'renewal_negotiation',
          'renewal_negotiation_waiting_for_reply', 'waiting_for_refund', 'waiting_for_tier_1_approval', 'waiting_for_tier_3_approval'
        ].includes(this.value.status) && this.value.site_target_id
      },
      linkCheckWidgetCollapsed() {
        return [
          'active_renewal', 'awaiting_fulfillment', 'awaiting_payment', 'awaiting_placement', 'awaiting_renewal',
          'existing', 'refund', 'refunded', 'rejected', 'renewal_negotiation',
          'renewal_negotiation_waiting_for_reply', 'waiting_for_refund'
        ].includes(this.value.status)
      },
      showHeatRating() {
        return [
          'dropped', 'future', 'negotiation', 'negotiation_reminder_sent', 'negotiation_waiting_for_reply', 'on_hold', 'zombie'
        ].includes(this.value.status)
      },
      relationshipWidthCollapsed() {
        return ![
          '2nd_initial_sent', '3nd_initial_sent', '4th_initial_sent', 'awaiting_contact_info','negotiation', 'negotiation_reminder_sent',
          'negotiation_waiting_for_reply', 'new', 'waiting_for_initial_reply'
        ].includes(this.value.status)
      }
    },
    methods: {
      setBlankNewEmail() {
        this.show_email_form = true;
        setTimeout(() => {
          this.$refs.emailForm.setReplyEmail({});
          window.scrollTo(0, this.$refs.emailFormHolder.offsetTop);
        }, 100)
      },
      refreshEmailList() {
        this.$emit('reload');
        this.$refs.emailList.fetchEmails();
      },
      update() {
        this.$emit('input', this.link)
        this.$emit('update')
      },
      setReplyEmail(settings) {
        this.show_email_form = true;
        setTimeout(() => {
          this.$refs.emailForm.setReplyEmail(settings);
          window.scrollTo(0, this.$refs.emailFormHolder.offsetTop);
        }, 300)
      },
      setDefaultToAndFrom(to, from, lastEmail) {
        this.defaultEmailTo = to;
        this.defaultEmailFrom = from;
        this.lastReceivedEmail = lastEmail;
        if(this.$refs.emailForm) {
          setTimeout(() => {
            this.$refs.emailForm.setUpQueryBulk();
          }, 300);
        }
      },
      blockEmail(email) {
        if(email && this.$refs.contactWidget && typeof this.$refs.contactWidget.blockEmail === 'function') {
          this.$refs.contactWidget.blockEmail(email, () => {
            this.$refs.emailList.fetchEmails()
          })
        }
      },
      setEmailFormContactUrl(email) {
        //console.log('I was triggered', email)
        this.$refs.emailForm.setContactUrl(email)
      },
      contactsLoaded() {
        if(this.email_contacts.length === 1 && !this.defaultEmailTo) {
          this.$refs.emailForm.setTo(this.email_contacts[0].email, true);
        }
      },
      refreshEmailPreview() {
        if(this.$refs.emailForm) {
          this.$refs.emailForm.setLivePreviewContent()
        }
      }
    }
  }
</script>

<style lang="scss" scoped>
  .block--emails {
    ::v-deep {
      .el-collapse-item__header {
        line-height: 1rem;
        height: auto;
      }
    }
  }

  .el-select-dropdown__item {
    height: auto;
  }
</style>
