<template>
  <div v-loading="loading">
    <div class="page-title">
      <h1>
        <logo
          v-model="$store.state.site.site.base_url"
          class="mr-2"
          :size="30"
        />
        <span>{{ $store.state.batch.batch.name }}<span class="faded-text"><span class="faded-strike">/</span>#{{$store.state.batch.batch.id}}</span></span>
      </h1>
      <div class="controls">
        <div v-if="this.batch && this.batch.status !== 'processing'" class="inline-block">
          <el-button
            size="mini"
            v-if="batch_first_review_link && canReview"
            icon="el-icon-timer"
            @click="startReview"
            type="success">
            Start review
          </el-button>
          <el-button
            size="mini"
            icon="el-icon-timer"
            @click="toggleBulkEmailingForm"
            type="success">
            Start bulk emailing ({{batch_email_status ? batch_email_status.awaiting.toLocaleString() : 0}})
          </el-button>
          <el-button
            size="mini"
            v-if="canEdit"
            icon="el-icon-refresh"
            @click="applyFilters"
            type="primary">
            re-apply filters
          </el-button>
          <el-button
            size="mini"
            @click="copyBatch"
            icon="el-icon-copy-document" type="info">
            Create Copy
          </el-button>
          <div class="ml-2 inline-block" v-if="canEdit">
            <el-dropdown @command="dedupe" size="small">
              <el-button type="primary" size="mini">
                Dedupe... <i class="el-icon-arrow-down el-icon--right"></i>
              </el-button>
              <el-dropdown-menu slot="dropdown">
                <el-dropdown-item command="">Batch links</el-dropdown-item>
                <el-dropdown-item :command="true">Domains</el-dropdown-item>
              </el-dropdown-menu>
            </el-dropdown>
          </div>
        </div>
        <div v-if="canRestartBatch" class="inline-block ml-2">
          <el-button
              size="mini"
              icon="el-icon-timer"
              @click="startBatch"
              type="primary">
            Rerun Batch
          </el-button>
        </div>
      </div>
    </div>
    <div class="columns">
      <div class="column w-full">
        <el-tabs class="custom-tabs-v1" v-if="ready" v-model="activeTabName" @tab-click="tabsChanged" ref="tabs">
          <el-tab-pane label="Links" name="links" v-if="$store.getters['auth/hasPermission']('link.read')" lazy>
             <span slot="label">
               <a href="?tab=links" @click.prevent>Links</a>
             </span>
            <links :show-title="false" export-mode="BATCHLINKS" export-name="all batch links" @on-mass-update="reloadBatch" allow-mass-update ref="links" />
          </el-tab-pane>
          <el-tab-pane label="Leads" name="leads"  v-if="$store.getters['auth/hasPermission']('lead.read')" lazy>
             <span slot="label">
               <a href="?tab=leads" @click.prevent>Leads</a>
             </span>
            <links :show-title="false" include-exchange-prices export-mode="LINKBUILDING" @on-mass-update="reloadBatch" export-name="all approved leads"
                   :active-only="true" :batch-id="$store.state.batch.batch.id" ref="leads"  />
          </el-tab-pane>
          <el-tab-pane label="Failed leads" name="failed links"  v-if="$store.getters['auth/hasPermission']('lead.read')" lazy>
             <span slot="label">
               <a href="?tab=failed links" @click.prevent>Failed links</a>
             </span>
            <links :show-title="false" include-exchange-prices export-mode="LINKBUILDING" export-name="all approved leads"
                   :failed="true" :batch-id="$store.state.batch.batch.id" ref="failed"  />
          </el-tab-pane>
          <el-tab-pane label="Notes and tasks" name="notes" lazy>
             <span slot="label">
               <a href="?tab=notes" @click.prevent>Notes</a>
             </span>
            <notes-widget resource-type="LinkLeadsBatch" :resource-id="batch.id" v-if="batch.id" :default-assignee-id="batch.owner_id" />
          </el-tab-pane>
          <el-tab-pane label="Batch configuration" name="configuration">
             <span slot="label">
               <a href="?tab=configuration" @click.prevent>Configuration</a>
             </span>
            <create-batch
              ref="batchConfig"
              :updatable="canEdit" />
          </el-tab-pane>
          <el-tab-pane label="Info" name="info" lazy>
             <span slot="label">
               <a href="?tab=info" @click.prevent>Info</a>
             </span>
            <div class="max-w-screen-xl">
              <trieste-card title="Details">
                <div class="p-4">
                  <trieste-info-column label="Number of results yielded" number :value="$store.state.batch.batch.total_results"  />
                  <trieste-info-column label="Number of leads accepted" number :value="$store.state.batch.batch.total_leads"  />
                  <trieste-info-column label="Number of active leads" number :value="$store.state.batch.batch.total_leads_accepted"  />
                  <trieste-info-column label="Success %" :value="$store.state.batch.batch.success_ratio + '%'"  />
                  <trieste-info-column
                    v-if="['google_simple_query_v2', 'bing_search_api', 'baidu_search_api'].includes($store.state.batch.batch.batch_type)"
                    label="Keywords"
                    :value="$store.state.batch.batch.google_simple_query"
                  />
                  <trieste-info-column
                    v-else-if="$store.state.batch.batch.batch_type === 'html_data'"
                    label="URL List"
                    :value="$store.state.batch.batch.html_data"
                  />
                  <trieste-info-column
                    v-else-if="$store.state.batch.batch.batch_type === 'domain'"
                    label="domain"
                    :value="$store.state.batch.batch.domain"
                  />
                  <trieste-info-column
                    v-else-if="['single_page', 'page_url'].includes($store.state.batch.batch.batch_type)"
                    label="Page URL"
                    :value="$store.state.batch.batch.page_url"
                  />
                  <trieste-info-column
                    v-else
                    label="Page URL"
                    :value="$store.state.site.site.domain"
                  />
                  <trieste-info-column label="Batch type" :value="friendlyBatchName($store.state.batch.batch.batch_type)"  />
                  <trieste-info-column label="Created by">
                    {{ $store.state.batch.batch.creator ? `${$store.state.batch.batch.creator.firstname} ${$store.state.batch.batch.creator.lastname}` : 'N/A' }}
                     on {{ $store.state.batch.batch.created_at | momentLocal("MMMM DD, YYYY hh:mm A Z") }}
                  </trieste-info-column>
                  <trieste-info-column label="Updated by">
                    {{ $store.state.batch.batch.updated_by ? `${$store.state.batch.batch.updated_by.firstname} ${$store.state.batch.batch.updated_by.lastname}` : 'System' }}
                     on {{ $store.state.batch.batch.updated_at | momentLocal("MMMM DD, YYYY hh:mm A Z") }}
                  </trieste-info-column>
                  <trieste-info-column label="Discard previously found leads in Company" :value="$store.state.batch.batch.discard_company_site_links" />
                  <trieste-info-column label="Allow previously found links" :value="$store.state.batch.batch.not_discard_old_links" />
                  <trieste-info-column label="Discard links reviewed over 3 times" :value="$store.state.batch.batch.discard_leads_reviewed_3_times" />
                  <trieste-info-column label="Allow same domain leads" :value="$store.state.batch.batch.allow_same_domain_site_links" />
                  <trieste-info-column label="Only links in top 1m Tranco list" :value="$store.state.batch.batch.only_in_top_1m" />
                </div>
              </trieste-card>
            </div>
          </el-tab-pane>
        </el-tabs>
      </div>
    </div>

    <initial-bulk-emailing :bulk_link="batch_first_email_link" ref="ibe" />
  </div>
</template>

<script>
  import CreateBatch from "./Create";
  import NotesWidget from "@/components/notes";
  import Links from "../../../Links/Index";
  import tabPosition from '@/components/mixins/tab_position';
  import InitialBulkEmailing from '@/components/views/batch/InitialBulkEmailing';
  import moment from 'moment';
  export default {
    components: {InitialBulkEmailing, NotesWidget, CreateBatch, Links},
    mixins: [tabPosition],
    metaInfo() {
      return {
        title: () => `Batch ${this.$route.params.batchId}`
      }
    },
    data() {
      return {
        loading: false,
        activeTabName: 'links',
        ready: false,
        batch: this.$store.state.batch.batch,
        batch_reviewed: null,
        batch_first_review_link: null,
        batch_first_email_link: null,
        batch_email_status: null,
        show_bulk_emailing_form: false,
        friendly_batch_names: {
          google_simple_query_v2: 'Google Serp Query',
          bing_search_api: 'Bing Serp Query',
          baidu_search_api: 'Baidu Serp Query',
          existing: 'Existing',
          domain: 'Domain backlinks',
          single_page: 'Page backlinks',
          page_url: 'External links on a page',
          html_data: 'Custom URL list',
        }
      }
    },
    mounted() {
      if(!this.$store.getters['auth/hasPermission']('batch.read')) {
        this.$message.error('You do not have permissions to view batches.');
        this.$router.push('/');
      }
      this.loading = true;
      this.$store.dispatch('company/getCompany', this.$route.params).then((company) => {
        this.$store.dispatch('batch/getBatch', this.$route.params).then((batch) => {
          this.$store.dispatch('campaign/getCampaign', { campaignId: batch.campaign_id }).then((campaign) => {
              this.$store.dispatch('site/getSite', { siteId: campaign.site_id }).then((site) => {
              //console.log('the site')
              this.$store.dispatch('client/getClient', {clientId: site.client_id}).then((client) => {
                this.$store.commit('global/setBreadcrumbs', [
                  {
                    path: '/',
                    label: company.name
                  },

                  {
                    path: `/company/${company.id}/clients`,
                    label: 'clients'
                  },
                  {
                    path: `/company/${company.id}/clients/${client.id}`,
                    label: client.name
                  },
                  {
                    path: `/company/${company.id}/clients/${client.id}?tab=sites`,
                    label: 'sites'
                  },
                  {
                    path: `/company/${company.id}/sites/${site.id}`,
                    label: site.domain
                  },
                  {
                    path: `/company/${company.id}/sites/${site.id}?tab=campaigns`,
                    label: 'campaigns'
                  },
                  {
                    label: this.$store.state.campaign.campaign.name,
                    path: `/company/${company.id}/campaigns/${campaign.id}`,
                  },
                  {
                    label: 'Batches',
                    path: `/company/${company.id}/campaigns/${campaign.id}?tab=batches`,
                  },
                  {
                    label: batch.name
                  }
                ])
              })
            })
          })

          this.batch = Object.assign({}, batch);
          this.batch_first_review_link = batch.review_link;
          this.batch_reviewed = batch.reviewed_status;
          this.batch_first_email_link = batch.next_emailable_link;
          this.batch_email_status = batch.initial_email_status;
          this.ready = true
          this.loading = false
        })
      })
    },
    computed: {
      canReview() {
        if (!this.isOwner && this.$store.getters['auth/hasPermission']('other.batch.review')) {
          return true
        } else if (this.isOwner && this.$store.getters['auth/hasPermission']('own.batch.review')) {
          return true
        }

        return false
      },
      canEdit() {
        if (!this.isOwner && this.$store.getters['auth/hasPermission']('other.batch.delete')) {
          return true
        } else if (this.isOwner && this.$store.getters['auth/hasPermission']('batch.update')) {
          return true
        }

        return false
      },
      isOwner() {
        return this.$store.state.auth.user.id === this.batch.owner_id
      },
      canRestartBatch() {
        if(!this.batch) return false;
        return this.batch.status === 'empty' || ( ['processing', 'processing_rld'].includes(this.batch.status) && moment(this.batch.created_at).unix() < moment().subtract('12', 'hours').unix() )
      }
    },
    methods: {
      copyBatch(){
        let params = this.$refs.batchConfig.getBatchParams();

        this.$router.push(`/company/${this.$route.params.companyId}/campaigns/${params.campaign_id}/batch/create?target_id=${params.default_site_target_id}&mode=copy&copy_params=${JSON.stringify(params)}`)
      },
      startReview() {
        if(this.batch_first_review_link) {
          this.$store.commit('batch/update', ['automatically_go_to_next_link', true])
          const link = this.batch_first_review_link
          this.$router.push(`/company/${this.$route.params.companyId}/links/${link.id}`)
        }
      },
      toggleBulkEmailingForm() {
        this.$refs.ibe.toggleBulkEmailingForm()
      },
      startBatch() {
        this.loading = true;
        this.$http.post(`/v1/companies/${this.$store.state.company.company.id}/batches/${this.batch.id}/start`)
          .then(() => {
            this.$message.success('successfully started the batch.')
            this.batch.status = 'processing'
          })
          .catch(() => {
            this.$message.error('Failed to start the batch')
          })
          .finally(() => {
            this.loading = false;
          })
      },
      applyFilters() {
        if(this.batch.status !== 'processing') {
          this.$confirm('Are you sure you want to re-apply the batch filters? This will remove any links that do not fit the criteria.')
            .then(() => {
              this.loading = true;
              this.$http.post(`/v1/companies/${this.$store.state.company.company.id}/batches/${this.batch.id}/reapply-filters`)
                .then((r) => {
                  //console.log('Request complete')
                  const d = r.data
                  if (d.removed_total === 0 ) {
                    this.$message.success('Successfully re-run the batch filters. No links were removed.')
                  } else if ( d.filtered_total === d.original_total) {
                    this.$message.success('Successfully re-run the batch filters. All links were removed.')
                  } else {
                    this.$message.success(
                      `Successfully re-run the batch filters. Out of ${d.original_total.toLocaleString()}, ${d.removed_total.toLocaleString()}
                    links were removed from the batch. ${d.filtered_total.toLocaleString()} now remain.`
                    )
                  }

                  this.reloadTables()
                  this.reloadBatch()
                }).catch(() => {
                  this.$message.error('Failed to re-apply filters to the batch')
              }).finally(() => {
                this.loading = false;
              })
            }).catch(() => {})
        }
      },
      dedupe(domains = false) {
        if(this.batch.status !== 'processing') {
          this.$confirm('Are you sure you want to dedupe? This will remove any links that do not fit the criteria.')
            .then(() => {
              this.$http.post(`/v1/companies/${this.$store.state.company.company.id}/batches/${this.batch.id}/dedupe`, {
                domains
              })
                .then((r) => {
                  const d = r.data
                  if (d.removed_total === 0 ) {
                    this.$message.success('Successfully deduped the batch. No links were removed.')
                  } else if ( d.filtered_total === d.original_total) {
                    this.$message.success('Successfully deduped the batch. All links were removed.')
                  } else {
                    this.$message.success(
                      `Successfully deduped the batch. Out of ${d.original_total.toLocaleString()}, ${d.removed_total.toLocaleString()}
                    links were removed from the batch. ${d.filtered_total.toLocaleString()} now remain.`
                    )
                  }

                  this.reloadTables()
                  this.reloadBatch()
                })
            }).catch(() => {})
        }
      },
      friendlyBatchName(name) {
        if(this.friendly_batch_names[name]) {
          return this.friendly_batch_names[name];
        }

        return name.replace(/_/g, ' ')
      },
      reloadBatch() {
        this.loading = true;
        this.$store.dispatch('batch/getBatch', this.$route.params).then((batch) => {
          this.batch = Object.assign({}, batch);
          this.batch_first_review_link = batch.review_link;
          this.batch_reviewed = batch.reviewed_status;
          this.batch_first_email_link = batch.next_emailable_link;
          this.batch_email_status = batch.initial_email_status;
          this.loading = false;
        })
      },
      reloadTables() {
        if(this.$refs.links) {
          this.$refs.links.refresh()
        }

        if(this.$refs.leads) {
          this.$refs.leads.refresh()
        }

        if(this.$refs.failed) {
          this.$refs.failed.refresh()
        }
      }
    }
  }
</script>

<style scoped>
</style>
