<template>
  <div>
    <trieste-table
      :end-point="'/v1/companies/' + $store.state.company.company.id + '/email-templates'"
      :fields="fields"
      title="Email templates"
      item-type="email templates"
      ref="table"
      :show-title="false"
      saved-table-sort-name="emailtemplates"
      :filters="filters"
      :params="{with_sites: true}"
    >
      <template v-slot:header-right>
        <el-button type="success" size="medium" v-if="$store.getters['auth/hasPermission']('template.create')"  icon="el-icon-edit-outline" @click="showCreateEmailTemplate = true">Create</el-button>
      </template>
      <template v-slot:actions-slot="props">
        <el-button
          v-if="$store.getters['auth/hasPermission']('template.update')"
          size="small"
          icon="el-icon-edit"
          @click="() => { new_email_template = Object.assign({}, props.row); showCreateEmailTemplate = true }"
          type="primary">
          Edit
        </el-button>
      </template>

      <template v-slot:body-slot="props">
        {{props.row.body.length | shortenText(100) }}
      </template>
    </trieste-table>
    <trieste-dialog
      :show.sync="showCreateEmailTemplate"
      :title="new_email_template.id ? 'Edit ' + new_email_template.name : 'Create a new email template'"
      :show-close="false"
      :modal="false"
      width="800px"
    >
      <trieste-dialog
        width="30%"
        title="if/else statement"
        :show.sync="showIfStatementDialog"
        :append-to-body="true">
        <div class="p-6 pb-2">
          <el-form :rules="email_if_statement_rules" :model="email_if_statement" label-width="120px" label-position="left" ref="ifStatementForm">
            <el-form-item label="If" prop="if" required>
              <el-select
                v-model="email_if_statement.if"
                size="small"
                class="w-full"
              >
                <el-option
                  v-for="(placeholder, i) in placeholders"
                  :key="'placeholders_' + i"
                  :label="placeholder.label"
                  :value="placeholder.code"
                />
              </el-select>
            </el-form-item>
            <el-form-item label="Else" prop="else">
              <el-input type="text" v-model="email_if_statement.else" />
            </el-form-item>
          </el-form>
        </div>
        <template v-slot:footer-right>
          <el-button type="danger" icon="el-icon-close" size="small" @click="closeIfStatementDialog">Cancel</el-button>
          <el-button type="success" icon="el-icon-edit-outline" size="small" @click="addIfStatement">Confirm</el-button>
        </template>
      </trieste-dialog>
      <div class="p-6 pb-2">
        <el-form :rules="new_email_template_rules" :model="new_email_template" label-position="left" label-width="120px" ref="siteForm" v-loading="loading">
          <el-form-item label="Name" prop="name">
            <el-input type="text" v-model="new_email_template.name" />
          </el-form-item>
          <el-form-item label="Subject" prop="subject">
            <el-input type="text" v-model="new_email_template.subject" />
          </el-form-item>
          <el-form-item>
            <div class="columns">
              <div class="column w-full">
                <div class="flex">
                  <div class="mr-2 ml-auto">
                    <span class="text-xs text-gray-600">Placeholders</span>
                  </div>
                  <div class="mr-2">
                    <el-select
                      v-model="selected_placeholder"
                      size="small"
                      @change="insertPlaceholderToBody"
                    >
                      <el-option
                        v-for="(placeholder, i) in placeholders"
                        :key="'placeholders_' + i"
                        :label="placeholder.label"
                        :value="placeholder.code"
                      />
                    </el-select>
                  </div>
                  <div>
                    <el-button size="small" @click="showIfStatementDialog = true">
                      If statement...
                    </el-button>
                  </div>
                </div>
              </div>
            </div>
          </el-form-item>
          <el-form-item label="Body" prop="body">
            <el-tabs class="custom-tabs-v1" v-model="selected_tab">
              <el-tab-pane label="Compose" name="compose">
                <el-input type="textarea" v-model="new_email_template.body" @change="setCursorPosition" @focus="setContentKeys" @blur="unsetContentKeys" @input="setCursorPosition" ref="newTemplateContentBody" :rows="10" />
              </el-tab-pane>
              <el-tab-pane label="Preview" name="preview">
                <preview-template
                  :subject="new_email_template.subject"
                  :content="new_email_template.body" />
              </el-tab-pane>
            </el-tabs>
          </el-form-item>
          <el-form-item label="Auto add sites" prop="all_sites">
            <el-select v-model="new_email_template.all_sites">
              <el-option :value="true" label="Yes" />
              <el-option :value="false" label="No" />
            </el-select>
            <br/>
            <small>If enabled, newly created company sites will automatically be added to the allowed list.</small>
          </el-form-item>
          <el-form-item label="Sites" prop="sites">
            <el-select v-model="new_email_template.sites" multiple filterable class="w-full">
              <el-option v-for="(site, i) in sites" :key="i" :value="site.id" :label="`${site.domain} (${site.client.name})`" />
            </el-select>
            <div class="text-right">
              <a href="#" class="font-medium text-xs text-blue-600" @click.prevent="selectAllSites">Select all sites</a>  /
              <a href="#" class="font-medium text-xs text-red-600" @click.prevent="unselectAllSites">Unselect all sites</a>
            </div>
          </el-form-item>
        </el-form>
      </div>
      <template v-slot:footer-left>
        <el-button type="danger" icon="el-icon-delete" size="small"
                   v-if="$store.getters['auth/hasPermission']('template.delete') && new_email_template.id"
                   @click="deleteTemplate(new_email_template)">Permanently delete this template</el-button>
      </template>
      <template v-slot:footer-right>
        <el-button type="danger" icon="el-icon-close" size="small" @click="closeCreateEmailTemplateDialog">Cancel</el-button>
        <el-button type="info" icon="el-icon-edit-outline" size="small" @click="createEmailTemplate">{{ new_email_template.id ? 'Edit' : 'Create' }}</el-button>
      </template>
    </trieste-dialog>
  </div>
</template>
<script>
  import emailPlaceholders from "@/json/email_placeholders.json";
  import PreviewTemplate from "@/components/views/emails/PreviewTemplate";
  export default {
    components: {PreviewTemplate},
    mounted() {
      this.getResources()
    },
    data: function () {
      const minSitesValidator = (rule, value, callback) => {
        if(!Array.isArray(value) || value.length < 1) {
          return callback(new Error('Please select one site.'))
        }

        return callback()
      }
      return {
        selected_tab: 'compose',
        selected_placeholder: null,
        showCreateEmailTemplate: false,
        showIfStatementDialog: false,
        new_email_template: {
          id: null,
          body: '',
          subject: '',
          name: '',
          sites: [],
          all_sites: false
        },
        email_if_statement: {
          if: null,
          else: ''
        },
        email_if_statement_rules: {
          if: [
            { required: true, message: 'Please select a conditional placeholder', trigger: 'change', min: 1 }
          ]
        },
        new_email_template_rules: {
          body: [
            {required: true, min: 3, trigger: 'blur', message: 'Please enter a body'}
          ],
          subject: [
            {required: true, min: 3, trigger: 'blur', message: 'Please enter a subject'}
          ],
          name: [
            {required: true, min: 3, trigger: 'blur', message: 'Please enter a name'}
          ],
          /*sites: [
            { validator: minSitesValidator, message: 'Please select one site.'}
          ],*/
        },
        query: {
          rpp: 20,
          page: 1
        },
        loading: false,
        template_editor_cursor: 0,
        fields: [
          {
            name: 'name',
            sortField: 'name',
            title: 'name',
          },
          {
            name: 'subject',
            sortField: 'subject',
            title: 'Subject',
          },
          {
            name: 'body',
            title: 'Body',
          },
          {
            title: '',
            name: 'actions-slot',
            width: '100px',
            fixed: 'right'
          },
        ],
        sites: [],
        placeholders: emailPlaceholders,
        filters: [
          {
            label: 'Name'
          },
          {
            label: 'Subject'
          },
          {
            label: 'Body'
          }
        ]
      }
    },
    methods: {
      getResources() {
        const requests = [
          this.$http.get(`/v1/companies/${this.$store.state.company.company.id}/sites`, { params: { all: true } })
        ]

        this.loading = true;
        this.$http.all(requests).then(this.$http.spread((sites) => {
          this.sites = sites.data.data;
        }))
          .finally(() => {
            this.loading = false
          })
      },
      deleteTemplate(template) {
        //console.log(template)
        if(template.id && template.name) {
          this.$confirm('Are you sure you want to delete this template?', 'Are you sure?', {
            confirmButtonClass: 'el-button--danger'
          })
            .then(() => {
              this.$http.delete( `/v1/companies/${this.$store.state.company.company.id}/email-templates/${template.id}`)
                .then(() => {
                  this.$message.success('The email template has successfully been deleted.');
                  this.$refs.table.getData()
                  this.showCreateEmailTemplate = false;
                })
                .catch(() => {
                  this.$message.error('The was an error processing that request. Please try again later.')
                })
            })
            .catch(() => {
              // do nothign
            })
        }
      },
      createEmailTemplate() {
        this.$refs.siteForm.validate((valid) => {
          if(valid) {
            this.loading = true;

            let method = this.$http.post;
            let url = `/v1/companies/${this.$store.state.company.company.id}/email-templates`;

            if(this.new_email_template.id) {
              url += `/${this.new_email_template.id}`;
              method = this.$http.put;
            }

            method(url, this.new_email_template)
              .then(() => {
                this.$message.success('Successfully '+(this.new_email_template.id ? 'updated':'created')+' the email templates.');
                this.closeCreateEmailTemplateDialog();
                this.$refs.table.getData()
              })
              .catch((err) => {
                let message = formatResponseErrorMessage(err, 'createEmailTemplate')
                this.$message.error(message)
                console.error(err.toJSON(), message)
              })
              .finally(() => {
                this.loading = false;
              })
          }
        })
      },
      closeCreateEmailTemplateDialog() {
        this.$refs.siteForm.resetFields()
        this.new_email_template = {
          id: null,
          body: '',
          subject: '',
          name: '',
          sites: [],
          all_sites: false
        };
        this.showCreateEmailTemplate = false;
      },
      insertPlaceholderToBody() {
        this.appendContentToBodyCursor(this.selected_placeholder);
        this.selected_placeholder = null;
      },
      closeIfStatementDialog() {
        this.showIfStatementDialog = false;
        this.email_if_statement = {
          if: null,
          else: ''
        }
      },
      appendContentToBodyCursor(content) {
        if(this.new_email_template.body === '') {
          this.new_email_template.body += content;
        } else {
          const stringSplit = this.new_email_template.body.split('');
          stringSplit.splice(this.template_editor_cursor, 0, ...content.split(''));
          this.new_email_template.body = stringSplit.join('');
          this.template_editor_cursor += content.split('').length;
        }
      },
      addIfStatement() {
        this.$refs.ifStatementForm.validate(valid => {
          if(valid) {
            const conditionalStatement = this.email_if_statement.if.replace('<<<', '').replace('>>>', '')
            let statement = '';
            statement += `{% if ${conditionalStatement} %} ${this.email_if_statement.if}`;
            if(this.email_if_statement.else && this.email_if_statement.else.length > 0) {
              statement += ` {% else %} ${this.email_if_statement.else}`;
            }
            statement += ` {% endif %} `;
            this.appendContentToBodyCursor(statement);
            this.closeIfStatementDialog()
          }
        })
      },
      setCursorPosition() {
        this.template_editor_cursor = this.$refs.newTemplateContentBody.$refs.textarea.selectionEnd;
      },
      setContentKeys() {
        window.addEventListener('keyup', this.contentKeysFunction);
        window.addEventListener('click', this.contentKeysFunction);
      },
      unsetContentKeys() {
        window.removeEventListener('keyup', this.contentKeysFunction);
        window.removeEventListener('click', this.contentKeysFunction);
      },
      contentKeysFunction(e) {
        if(['ArrowLeft', 'ArrowUp', 'ArrowRight', 'ArrowDown'].includes(e.key) || e.type === 'click') {
          this.setCursorPosition();
        }
      },
      selectAllSites () {
        this.new_email_template.sites = this.sites.map(s => s.id)
      },
      unselectAllSites () {
        this.new_email_template.sites = [];
      }
    }
  }
</script>
